import React, { Component } from "react";

import "./styles.css";

/**
 * Composant Navbar permettant de construire une barre de navigation
 */
class Navbar extends Component {
  /**
   * Rendu du Composant Navbar
   * @returns : template HTML du composant Navbar
   */
  render() {
    return (
      <nav
        class="navbar navbar-container largeur-100"
        style={{ backgroundColor: "#001e50" }}
      >
        <div class="container-fluid">
          <a
            class="nav-link active"
            style={{ margin: 15 }}
            aria-current="page"
            href="#"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="white"
              class="bi bi-house-door"
              viewBox="0 0 16 16"
            >
              <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z" />
            </svg>
          </a>
          <div class="container d-flex justify-content-left" style={{ marginLeft: 0 }} >
            <a
              style={{ color: "white", marginTop: 5, whiteSpace: "normal" }} 
              class="navbar-brand"
              href="#"
            >
              MÉCATHÈQUE, la base de connaissances du manufacturing
            </a>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
