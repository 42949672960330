import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Score from '../score/score';

import "./styles.css";

/**
 * Composant FacetteProfs permettant de construire la liste des facettes des professions
 */
class FacetteProfs extends Component {

    /**
     * Toutes les variables dont a besoin le Composant et qui seront modifiées à travers l'application (variables d'état)
     * @param {*} props 
     */
    constructor(props) {
        super(props);
    }

    /**
     * Vérifie si une facette est cochée
     * @param {*} facette : Code de la facette sélectionnée
     */
    /*isChecked(facette, e) {
        //Reprend le tableau des professions défini dans les variables d'état
        const professions = this.props.listProfessions;
        //Met à jour l'état de 'checked' dans le tableau professions
        professions.forEach((prof) => {
            if (prof.code === facette.key) {
                e.target.checked = prof.checked;
                return prof.checked;
            } else if (this.props.isCheckedProf === false) {
                e.target.checked = false;
            }
        });
    }*/

    /**
     * Fonction déclenchée au moment de cocher/décocher une facette
     * @param {*} id : la valeur de la facette - code profession (ex.: '38')
     * @param {*} type : ici, professions
     * @param {*} e : événement onChange
     */
    facetteClick(id, type, e) {
        this.props.handleFacetteClick(id, type, e);
    }

    professionCount(profession) {
        const element = this.props.homeToFacette.find(elt => elt.key === profession.code);
        return element ? element.count : '';
    }

    /**
     * Rendu du Composant FacetteProfs
     * @returns : template HTML avec les différents appels aux Composants et fonctions JS
     */
    render() {
        
        const scoreMax = Math.max(...this.props.homeToFacette.map(obj => obj.count));

        return (
            <div>
                        <div>
                            <table>
                                {this.props.listProfessions.map(profession => {
                                    return (
                                        <tr key={profession.code}>
                                            <td className='nom'>
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox checked={profession.checked} onChange={this.facetteClick.bind(this, profession.code, "professions")} name={profession.code} size='small' sx={{
                                                        '&.Mui-checked': {
                                                            color: '#CA0538',
                                                        },
                                                        paddingTop: '0px',
                                                        paddingBottom: '0px'
                                                    }} />} label={profession.name} 
                                                    />
                                                </FormGroup>
                                            </td>
                                            <td className='score'>
                                                <Score niveau={ 100 * this.professionCount(profession) / scoreMax } nombre={ this.professionCount(profession) }></Score>
                                            </td>
                                        </tr>
                                    )
                                })}                         
                            </table>
                        </div>
            </div>
        )
    }
}

export default FacetteProfs;