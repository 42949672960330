import React, { Component } from 'react';
// import logoCetim from '../../assets/images/Logo-CETIM.png';
import logoCetim from '../../assets/images/logo_cetim.svg';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faClipboard, faBook } from '@fortawesome/free-solid-svg-icons';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import "./styles.css";

/**
 * Composant Resultat permettant d'afficher les fiches Mécathèque provenant d'ElasticSearch
 */
class Resultat extends Component {

    /**
     * Toutes les variables dont a besoin le Composant et qui seront modifiées à travers l'application (variables d'état)
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            searchResult: true,
            value: 0
        }
    }

    /**
     * Rendu du Composant Résultat
     * @returns : template HTML avec les différents appels aux variables passées en paramètre depuis la page Home.js
     */
    render() {
      return (
          <div>
              {this.props.results.map((post, index) => {
                  return (
                      <Card key={post.id || index} sx={{ display: 'flex', margin: { xs: '5px', md: '15px' } }}>
                          <Grid container spacing={0} sx={{ alignItems: 'flex-start' }}>
                              <Grid item xs={12} md={8}>
                                  <CardContent>
                                      <Typography sx={{ fontSize: '12px', color: '#6788B8', letterSpacing: '0.75px', textTransform: 'uppercase', fontWeight: 600 }} color="text.secondary" gutterBottom>
                                          {(post.source.mod_date === "") ? post.source.parent_type + ' - ' + new Date(post.source.publ_date).toLocaleDateString() : post.source.parent_type + ' - ' + new Date(post.source.mod_date).toLocaleDateString()}
                                      </Typography>
                                      <a
                                          style={{
                                              fontSize: '18px', /*fontSize: '24px'*/
                                              fontWeight: 700,
                                              color: '#001E50',
                                              /*letterSpacing: '1.8px',*/
                                              textDecoration: 'none',
                                              /*lineHeight: '32px'*/
                                          }}
                                          className="customLink"
                                          href={post.source.url_alias}
                                          target='_blank'
                                          rel="noopener noreferrer"
                                      >
                                          {post.source.title}
                                      </a>
                                      <Typography sx={{ fontSize: '14px', color: '#6A6A6A', letterSpacing: '0.75px', fontWeight: 400 }} variant="body2">
                                          {post.source.description.substring(0, 160) + ' ...'}
                                      </Typography>
                                  </CardContent>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                  <Box sx={{ width: '100%' }}>
                                      <CardMedia
                                          sx={{
                                              width: '100%',
                                              height: 'auto',
                                              padding: '15px'
                                          }}
                                          component="img"
                                          image={(post.source.img_url === "NONE" || post.source.img_url === "") ? logoCetim : post.source.img_url}
                                          alt="Logo Fiche Meca"
                                      />
                                  </Box>
                              </Grid>
                          </Grid>
                      </Card>
                  );
              })}
          </div>
      );
  }
}/* <Link to={'/fiche/' + post.id} className="customLink" style={{
    fontWeight: 'bold',
    color: 'black',
    textDecoration: 'none'
}} target='_blank'>{post.source.title}</Link>*/

export default Resultat;