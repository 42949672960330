import React, { Component } from 'react';

import logo from '../../assets/images/logo-mecallians.svg';

class FooterMecalliance extends Component {

    render() {
        return (
            <div class="footer-banner">
                <div class="position">
                    <div class="mecallians-banner row">
                        <a class="col-12 col-sm-4" href="https://www.mecallians.fr/" target="_blank">
                        <img title="Mecallians logo" alt="Mecallians logo" src={logo} loading="lazy" width="240" height="76"/>
                        </a>
                        <div class="moto col-12 col-sm-4 ">
                            Mecallians est la bannière commune des industries mécaniques, créée
                            à l'initiative de la FIM, du Cetim, de l'UNM, de Sofitech et de Cemeca.
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default FooterMecalliance;
