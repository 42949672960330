import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages/home/Home';
import Fiche from './pages/fiche/Fiche';
import Navbar from './components/navbar/Navbar';
import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Ia from './components/ia/Ia';

class App extends Component {
  render() {
    return (
      <Router>
        <Ia></Ia>
        <Header></Header>
        <Navbar/>
        <div className='row container'>
          <div className='col-xs-12 col-sm-1 col-xl-1'></div>
          <div className='col-xs-12 col-sm-11 col-xl-11'>         
            <Routes>
              <Route path='/' element={<Home/>} />
              <Route path='/fiche/:id' element={<Fiche />} />
            </Routes>
          </div>
        </div>
        <Footer></Footer>
      </Router>
    );
  }
}
export default App;
