import React, { Component, createRef } from 'react';
import "./styles.css";
import * as init from '../../init_vars';

import FacetteSelection from '../../components/facette/FacetteSelection';
import FacetteProfs from '../../components/facette/FacetteProfs';
import FacetteTypes from '../../components/facette/FacetteTypes';
import FacetteNewTypes from '../../components/facette/FacetteNewTypes';
import Resultat from '../../components/resultat/Resultat';
import PaginationCustom from '../../components/resultat/PaginationCustom';
import Search from '../../components/search/Search';
import Cursor from '../../components/search/Cursor';

import elasticService from '../../service/ElasticService';
import StatsMecatheque from '../../components/stats/StatsMecatheque';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

/**
 * Page d'Accueil de la Mécathèque
 */
class Home extends Component {

    /**
     * Toutes les variables dont a besoin le Composant et qui seront modifiées à travers l'application (variables d'état)
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            heigthSelection: 100,
            err: null,
            posts: [],
            message: '',
            results: [],
            valueCursor: 0,
            rescoreTime: 0,
            valueSearch: [],
            facetteProfessions: [],
            facetteTypes: [],
            checked: false,
            profsAgregas: [],
            typesAgregas: [],
            totalHits: 0,
            indexPage: 1,
            isWaitingResult: false,
            isLargeScreen: window.innerWidth > 768, // util pour ne pas deplier par défaut les facettes lorsque l'utilisateur est sur smartphone
            isCheckedTypes: false,
            disabledCursor: true,
            typeDocuments: [
                { name: 'Actions collectives en cours', code: 'Actions collectives en cours', checked: false },
                { name: 'Infos professions', code: 'Infos professions', checked: false },
                { name: 'Publications scientifiques et techniques', code: 'Publications scientifiques et techniques', checked: false },
                { name: "Résultats d'actions collectives", code: "Résultats d'actions collectives", checked: false },
                { name: 'Start-ups', code: 'Start-ups', checked: false },
                { name: 'Technologies prioritaires', code: 'Technologies prioritaires', checked: false },
                { name: 'Travaux liés à la normalisation et à la réglementation', code: 'Travaux liés à la normalisation et à la réglementation', checked: false },
                { name: 'Utilitaires', code: 'Utilitaires', checked: false },
                { name: 'Veille technologique', code: 'Veille technologique', checked: false },
            ],
            professions: [
                { name: 'Articles culinaires', code: '24', checked: false },
                { name: 'Chaudonnerie, tuyauterie, tôlerie', code: '15', checked: false },
                { name: 'Dispositifs médicaux', code: '2E', checked: false },
                { name: 'Décolletage', code: '18', checked: false },
                { name: 'Découpage-Emboutissage', code: '12', checked: false },
                { name: 'Fixations', code: '21', checked: false },
                { name: 'Fonderie', code: '1A', checked: false },
                { name: 'Forge', code: '11', checked: false },
                { name: 'Fours et Matériels pour la Sidérurgie', code: '3A', checked: false },
                { name: 'Machines agricoles', code: '31', checked: false },
                { name: 'Machines thermodynamiques', code: '33', checked: false },
                { name: 'Machines-outils et productique', code: '32', checked: false },
                { name: 'Manutention-levage, Stockage', code: '39', checked: false },
                { name: 'Matériels de bureau', code: '2F', checked: false },
                { name: 'Matériels destinés à l\'alimentaire', code: '3B', checked: false },
                { name: 'Matériels pour la chimie', code: '3C', checked: false },
                { name: 'Matériels pour le papier, le carton, l\'imprimerie', code: '37', checked: false },
                { name: 'Matériels textiles', code: '36', checked: false },
                { name: 'Mesure et Pesage', code: '2D', checked: false },
                { name: 'Mobilier', code: '2A', checked: false },
                { name: 'Moteurs, Compresseurs, Turbines', code: '34', checked: false },
                { name: 'Moules', code: '19', checked: false },
                { name: 'Mécanique industrielle', code: '14', checked: false },
                { name: 'Outillage à main', code: '22', checked: false },
                { name: 'Outils coupants', code: '25', checked: false },
                { name: 'Pompes', code: '29', checked: false },
                { name: 'Quincaillerie', code: '23', checked: false },
                { name: 'Ressorts', code: '2C', checked: false },
                { name: 'Robinetterie', code: '27', checked: false },
                { name: 'Roulements', code: '2B', checked: false },
                { name: 'Soudage', code: '16', checked: false },
                { name: 'Traitements de surface', code: '13', checked: false },
                { name: 'Transformation des plastiques', code: '35', checked: false },
                { name: 'Transmissions hydrauliques et pneumatiques', code: '28', checked: false },
                { name: 'Transmissions mécaniques', code: '26', checked: false },
                { name: 'Travaux Publics, Mines, Forage', code: '38', checked: false },
                { name: 'Non défini', code: 'undefined', checked: false }
            ],
        };
        this.divFacettesRef = React.createRef(); // util pour le calcul de la hauteur de l'ascenceur pour les facettes
        this.containerResultatsRef = React.createRef(); // util pour remonter en haut des resultats quand on clique sur le pagination
    }

    /**
     * Appel au service ElasticSearch pour la recherche de documents (serveur-serveur)
     *
     * @param {*} query : Liste des mot(s)-clé(s) saisie par l'utilisateur
     * @param {*} rescore_time : Valeur entière (entre 0 et 2) du curseur récence/pertinence (0: récence seule)
     * @param {*} professions : Liste des facettes professions sélectionnées
     * @param {*} typesDoc : Liste des facettes types de documents sélectionnées
     * @param {*} startPage : Numéro de page de recherche
     */
    callElasticForSearch(query, rescore_time, professions, typesDoc, startPage) {
        elasticService.searchES(query, rescore_time, professions, typesDoc, startPage).then(response => {
            this.setState({ posts: response["hits"], profsAgregas: response["professions"], typesAgregas: response["types"],
             totalHits: response["nbHits"], isWaitingResult: false });
        });
    }

    /**
     * Appel au service ElasticSearch pour la suggestion de mots-clés (client-serveur)
     *
     * @param {*} postData : Requête construite pour avoir une liste de suggestions
     */
    callElasticForSuggest(postData) {
        elasticService.suggestES(postData).then(json => {
            this.setState({ results: json.hits.hits });
        });
    }

    // Utilisé pour gérer l'utilisation des guillements dans la recherche initiale via paramètre ?q= dans l'url de l'appli
    splitStringPreservingQuotes(input) {
        const regex = /(?:[^\s"]+|"[^"]*")+/g;
        const matches = input.match(regex);
        return matches.map(word => word.replace(/"/g, ''));
    }

    /**
     * Actions à réaliser au lancement de l'application
     */
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        const top = this.divFacettesRef.current.getBoundingClientRect().top + 20; // position top absolue des facettes
        this.resizeObserver = new ResizeObserver(entries => {
          for (let entry of entries) {
            this.setState({ heigthSelection: entry.contentRect.height + top }); // pour calculer la hauteur de l'ascenceur
          }
        });
        this.observeDiv();

        // Gestion de recherche initiale via paramètre ?q= dans l'url de l'appli
        const searchParams = new URLSearchParams(window.location.search);
        const queryParam = searchParams.get('q');
        if (queryParam) {
            const terms = this.splitStringPreservingQuotes(queryParam);
            if (terms.length > 0) {
                // Mettre à jour l'état avec la valeur du paramètre 'q'
                this.setState({ valueSearch: terms }, () => {
                    // Effectuer une recherche initiale avec cette valeur
                    this.handleClickFromHome();
                });
            }
        }

        const query = this.state.valueSearch;
        const rescore_time = this.state.rescoreTime;
        const professions = this.state.facetteProfessions;
        const typesDoc = this.state.facetteTypes;
        const startPage = 0;
        this.setState({ valueCursor: 0 }); // +10 aurait été trompeur -> on met au centre, même si les docs seront les + récents
        this.callElasticForSearch(query, rescore_time, professions, typesDoc, startPage);
    }

    selectValueFromHome(value) {
        this.setState({ valueSearch: value });
    }
    
      componentDidUpdate() {
        this.observeDiv();
      }
    
      componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        if (this.resizeObserver) {
          this.resizeObserver.disconnect();
        }
      }

      observeDiv() {
        if (this.divFacettesRef.current instanceof Element) {
          this.resizeObserver.observe(this.divFacettesRef.current);
        }
      }

      handleResize = () => {
        const top = this.divFacettesRef.current.getBoundingClientRect().top + 20;
        this.setState({ heigthSelection: this.divFacettesRef.current.clientHeight + top });
    }

    /**
     * Fonction appelée au changement de la saisie de l'utilisateur (barre de recherche)
     * @param {*} e : événement onChange
     * @param {*} value : valeur saisie par l'utilisateur
     */
    onValueChangeFromHome(e, value) {
        if (value !== "" && value != undefined) {
            //Requête construite pour chercher toutes les suggestions de mots-clés
            const postData = {
                "query": {
                    "multi_match": {
                        "query": value,
                        "type": "bool_prefix",
                        "fields": [
                            "expression",
                            "expression._2gram",
                            "expression._3gram"
                        ]
                    }
                }
            };
            //Appel au service Elastic pour la suggestion de mots-clés
            this.callElasticForSuggest(postData);
        }
    };

    /**
     * Fonction appelée au clic de l'utilisateur sur le bouton de recherche
     * Elle prend en compte la valeur du curseur, le ou les mots-clés saisis, les facettes sélectionnées et le numéro de page de recherche
     */
    handleClickFromHome() {
      console.log('handleClickFromHome');
        const query = this.state.valueSearch;
        this.setState({ disabledCursor: query === "" || query.length === 0 || query == undefined });
        const startPage = 0;
        if (query.length === 0) {
            // cas d'une requête sur toute la base --> on repousse le curseur à gauche (docts les + récents)
            // console.log("recherche générale chronologique...");
            this.setState({ valueCursor: 0 });
            this.callElasticForSearch(query, 0, this.state.facetteProfessions, this.state.facetteTypes, startPage, true);
        } else {
            // un terme a été saisi -> on repousse le curseur à droite (pertinence)
            this.setState({ valueCursor: 2 });
            this.callElasticForSearch(query, 2, this.state.facetteProfessions, this.state.facetteTypes, startPage, false);
        }
    }

    /**
     * Fonction appelée au moment du changement de valeur du curseur
     * Les 3 valeurs vont de 0 à 2,
     * 0 : récence seule (pas d'utilisation de scoring)
     * 1 : "mix" avec rescore
     * 2 : pertinence "classique" seule
     * Pour une valeur == 1, une 1ère "surcote" (par rescoring) de la récence s'appliquera sur des documents saisis
     * dans une période passée (partant d'aujourd'hui) de 4 ou 5 ans (avec un "boost" multiplicatif),
     * puis une 2ème "décote" sera appliquée en fonction de l'ancienneté de chaque document.
     *
     * @param {*} newValue : la nouvelle valeur saisie par l'utilisateur à l'aide du curseur
     */
    handleCursorChange(newValue) {
        this.setState({
            valueCursor: newValue,
            rescoreTime: newValue,
            indexPage: 1
        });
        // Appel au service ElasticSearch pour afficher directement les résultats en fonction des nouveaux critères
        this.callElasticForSearch(this.state.valueSearch, newValue, this.state.facetteProfessions, this.state.facetteTypes, 0);
    }

    /**
     * Ajout des mots-clés saisis et sélectionnés par l'utilisateur
     * @param {*} value : mot-clé saisi et validé par l'utilisateur
     */
    selectValueFromHome(value) {
        this.setState({ valueSearch: value });
     //   this.setState({ disabledCursor: value === "" || value.length === 0 || value == undefined });
    }

    /**
     * Gère la sélection de facettes pour les professions et les types de documents
     * @param {*} id : soit le code profession, soit la valeur du type de document
     * @param {*} type : facette de type professions ou types
     * @param {*} e : évenement onChange
     */
    facetteClick(id, type, e) {
        const search_query = this.state.valueSearch;
        // Récupation de la liste des professions et types de documents actuelles
        let arrayProfessions = [...this.state.facetteProfessions];
        let arrayTypes = [...this.state.facetteTypes];
        let copyProfessions = [...this.state.professions];
        if (type === "professions") {
            // Recherche de la position de la profession dans la liste des professions
            const index = copyProfessions.findIndex((list) => list.code === e.target.name);
            // Ajout de la profession cochée dans une liste qui sera envoyée à Elastic
            if (e.target.checked === true) {
                arrayProfessions.push(id);
                copyProfessions[index].checked = true;
                this.setState({ facetteProfessions: arrayProfessions, professions: copyProfessions });
            } else {
                // Supprime les professions décochées de la liste
                copyProfessions[index].checked = false;
                let indexArray = arrayProfessions.indexOf(id);
                if (indexArray !== -1) {
                    arrayProfessions.splice(indexArray, 1);
                    this.setState({ facetteProfessions: arrayProfessions, professions: copyProfessions });
                }
            }
        } else {
            // Ajout du type de document coché dans une liste qui sera envoyée à Elastic
            if (e.target.checked === true) {
                arrayTypes.push(id);
                this.setState({ facetteTypes: arrayTypes, isCheckedTypes: true });
            } else {
                // Suppression des types de documents décochés de la liste
                let index = arrayTypes.indexOf(id);
                if (index !== -1) {
                    arrayTypes.splice(index, 1);
                    this.setState({ facetteTypes: arrayTypes, isCheckedTypes: false });
                }
            }
        }
        this.setState({ indexPage: 1 });
        const { valueCursor } = this.state;
        this.setState({ rescoreTime: valueCursor });
        // Appel au service ElasticSearch pour afficher directement les résultats en fonction des nouveaux critères
        this.callElasticForSearch(search_query, valueCursor, arrayProfessions, arrayTypes, 0);

    }

    newFacetteClick(liste, id, type, e) {
        const search_query = this.state.valueSearch;
        // Récupération de la liste des professions et types de documents actuelles
        let arrayProfessions = [...this.state.facetteProfessions];
        let arrayTypes = [...this.state.facetteTypes];
        let copyProfessions = [...this.state.professions];
        let copyTypeDocuments = [...this.state.typeDocuments];
        if (type === "professions") {
            // Recherche de la position de la profession dans la liste des professions
            const index = copyProfessions.findIndex((list) => list.code === e.target.name);
            // Ajout de la profession cochée dans une liste qui sera envoyée à Elastic
            if (e.target.checked === true) {
                arrayProfessions.push(id);
                copyProfessions[index].checked = true;
                this.setState({ facetteProfessions: arrayProfessions, professions: copyProfessions });
            } else {
                // Supprime les professions décochées de la liste
                copyProfessions[index].checked = false;
                let indexArray = arrayProfessions.indexOf(id);
                if (indexArray !== -1) {
                    arrayProfessions.splice(indexArray, 1);
                    this.setState({ facetteProfessions: arrayProfessions, professions: copyProfessions });
                }
            }
        } else {
            // Recherche de la position de la profession dans la liste des professions
            const index = copyTypeDocuments.findIndex((list) => list.code === e.target.name);
            // Ajout de la profession cochée dans une liste qui sera envoyée à Elastic
            if (e.target.checked === true) {
                arrayTypes.push(id);
                copyTypeDocuments[index].checked = true;
                this.setState({ facetteTypes: arrayTypes, typeDocuments: copyTypeDocuments });
            } else {
                // Supprime les professions décochées de la liste
                copyTypeDocuments[index].checked = false;
                let indexArray = arrayTypes.indexOf(id);
                if (indexArray !== -1) {
                    arrayTypes.splice(indexArray, 1);
                    this.setState({ facetteTypes: arrayTypes, typeDocuments: copyTypeDocuments });
                }
            }
        }
        this.setState({ indexPage: 1 });
        const { valueCursor } = this.state;
        this.setState({ rescoreTime: valueCursor });
        // Appel au service ElasticSearch pour afficher directement les résultats en fonction des nouveaux critères
        this.callElasticForSearch(search_query, valueCursor, arrayProfessions, arrayTypes, 0);
    }

    /**
     * Changement de page de recherche (précédent ou suivant)
     * @param {*} value : le numéro de page
     */
    changePage(value) {
        // console.log('appel à changePage');
        // Calcul de la valeur "from" d'Elastic qui permet de rechercher à partir d'un certain nombre de documents
        let indexOfLastPost = (value - 1) * init.NB_ITEMS_PER_PAGE;
        this.setState({ indexPage: value, isWaitingResult: true }, () => {
            if (this.containerResultatsRef.current) {
                //this.containerResultatsRef.current.scrollTop = 0;  
                //this.containerResultatsRef.current.scrollTo({ top: 0, behavior: 'smooth' });
              }
    
            // Appel au service ElasticSearch pour afficher directement les résultats
            this.callElasticForSearch(
                this.state.valueSearch,
                this.state.rescoreTime,
                this.state.facetteProfessions,
                this.state.facetteTypes,
                indexOfLastPost
            );
        });
    }

    /**
     * Rendu de la page d'Accueil avec les différents composants
     * @returns : template HTML avec les différents appels aux fonction JS et aux Composants
     */
    render() {
        const { posts } = this.state;
        const { results } = this.state;
        const { profsAgregas } = this.state;
        const { typesAgregas } = this.state;
        const { professions } = this.state;
        const { typeDocuments } = this.state;
        const valueCursor = this.state.valueCursor;
        const hits = this.state.totalHits;
        const index = this.state.indexPage;
        const isCheckedTypes = this.state.isCheckedTypes;
        const maxHeightFacettes = `calc(100vh - ${this.state.heigthSelection}px)`;
        const { isLargeScreen } = this.state;
        const { disabledCursor } = this.state;
        const { valueSearch } = this.state;
        const hasCheckedElements = typeDocuments.some(elt => elt.checked) || professions.some(elt => elt.checked);
     
        return (
            <div className="App" >
                <header className="App-header" style={{ marginTop: 20}}>  
                    <div className='row'>
                            <Search resultsES={results} onValueChange={this.onValueChangeFromHome.bind(this)} handleClick={this.handleClickFromHome.bind(this)} selectValue={this.selectValueFromHome.bind(this)} initialValue={valueSearch} />
                            <Cursor valueSearch={valueCursor} handleChange={this.handleCursorChange.bind(this)} disabled={disabledCursor} />
                    </div>
                </header>
                <main>
                    <div className='row'>
                        <div className='col-sm-12 col-md-4'>
                            <div>
                                <div class="facettes">
                                <Accordion ref={this.divFacettesRef} defaultExpanded={true} style={{display: hasCheckedElements ? 'block' : 'none', margin: '5px 5px 15px 5px' }} >
                                     <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel0-content"
                                        id="panel0-header"
                                    >
                                        <Typography><h5>Sélection</h5></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{padding: 0}}>
                                      <FacetteSelection className="selection" homeToFacetteProfessions={profsAgregas} homeToFacetteTypeDocuments={typesAgregas} listTypeDocuments={typeDocuments} listProfessions={professions} handleFacetteClick={this.newFacetteClick.bind(this)} isCheckedProf={professions.checked} />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion defaultExpanded={isLargeScreen} style={{margin: '5px 5px 15px 5px' }} >
                                     <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography><h5>Type de documents</h5></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{padding: 0}}>
                                        <FacetteNewTypes homeToFacette={typesAgregas} listTypeDocuments={typeDocuments} handleFacetteClick={this.newFacetteClick.bind(this)} isCheckedType={isCheckedTypes} />
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion defaultExpanded={isLargeScreen} style={{margin: 5 + 'px'}} >
                                     <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                    >
                                        <Typography><h5>Professions</h5></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{padding: 0}}>
                                    <FacetteProfs homeToFacette={profsAgregas} listProfessions={professions} handleFacetteClick={this.facetteClick.bind(this)} isCheckedProf={professions.checked} />
                                    </AccordionDetails>
                                </Accordion>                                    
                                </div>
                            </div>
                        </div>
                        <div ref={this.containerResultatsRef} className='col-sm-12 col-md-8 resultats' style={{ marginTop: "20px" }}>
                            <div style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '20px' }}>
                                <span style={{ color: "#CA0538" }}>{hits}</span>
                                {(hits > 1) ? <span> RESULTATS TROUVES</span> : <span> RESULTAT TROUVE</span>}
                            </div>
                            {hits > 0 &&
                                <div>
                                    <Resultat results={posts} />
                                    <PaginationCustom totalHits={hits} setPage={this.changePage.bind(this)} valuePage={index} />
                                </div>
                            }
                        </div>
                    </div>
                </main >

            </div >
        );
    }
}

export default Home;